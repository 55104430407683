<template>
  <div class="brevo-chat"></div>
</template>

<script>
export default {
  name: "BrevoChat",
  mounted() {
    this.loadBrevoScript();
  },
  methods: {
    loadBrevoScript() {
      window.BrevoConversationsID = "5dfa19dea6dbfa0859447b56";
      window.BrevoConversations =
        window.BrevoConversations ||
        function () {
          (window.BrevoConversations.q =
            // eslint-disable-next-line prefer-rest-params
            window.BrevoConversations.q || []).push(arguments);
        };

      const script = document.createElement("script");
      script.async = true;
      script.src =
        "https://conversations-widget.brevo.com/brevo-conversations.js";

      document.head.appendChild(script);
    },
  },
};
</script>

<style>
/* Add any specific styling for your chat component here */
</style>
