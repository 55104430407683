import ApiService from "@/core/services/ApiService";
import {
  getToken,
  saveToken,
  destroyToken,
  ID_TOKEN_KEY,
} from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
declare module "axios" {
  export interface AxiosRequestConfig {
    manufacturer_number?: string;
    packetId?: string;
  }
}
import { urlEncode } from "@/core/helpers/utils";

export interface User {
  name: string;
  surname: string;
  //email: string;
  //password: string;
  code: string;
  magicLink: string;
}

export interface UserAuthInfo {
  errors: Array<string>;
  user: User;
  token: string | null;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = [];
  token = getToken(ID_TOKEN_KEY);
  user = {} as User;

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  @Action({ rawError: true })
  [Actions.IS_USER_AUTHENTICATED](): boolean {
    const token = JSON.parse(this.token ?? "");
    if (token != null && token.loginTime != null) {
      if (token.password != null) {
        this.context.commit(Mutations.PURGE_AUTH);
        this.context.commit(Mutations.CLEAR_PACKETS);
        return false;
      } else {
        const credentials = {
          email: token.email,
          token: token.token,
        };
        ApiService.post("websps/user", urlEncode(credentials))
          .then(({ data }) => {
            this.context.commit(Mutations.SET_USER, data);
          })
          .catch((error) => {
            console.error(error);
            this.context.commit(Mutations.SET_ERROR, error);
          });
        if (credentials.token == null) {
          //resolve("sign-up");
          return false;
        }
        return !!this.token;
      }
    } else {
      if (token.loginTime == null) {
        this.context.commit(Mutations.PURGE_AUTH);
        this.context.commit(Mutations.CLEAR_PACKETS);
        return false;
      }
      return !!this.token;
    }
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors(): Array<string> {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_AUTH](credentials) {
    credentials.loginTime = Date.now();
    this.token = JSON.stringify(credentials);
    this.errors = [];
    saveToken(ID_TOKEN_KEY, JSON.stringify(credentials));
  }

  @Mutation
  [Mutations.SET_USER](user) {
    user["magicLink"] = user["magic_link"];
    delete user["magic_link"];
    this.user = user;
  }

  //@Mutation
  //[Mutations.SET_PASSWORD](password) {
  //  this.user.password = password;
  //}

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.token = null;
    this.user = {} as User;
    this.errors = [];
    destroyToken(ID_TOKEN_KEY);
  }

  @Action({ rawError: true })
  [Actions.CHECK](token): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post("websps/check", token)
        .then(async (response) => {
          const { email, token, id, code } = response.data;
          ApiService.setHeader();
          ApiService.post("websps/user", urlEncode(response.data))
            .then(async ({ data }) => {
              this.context.commit(Mutations.SET_USER, data);
            })
            .catch(({ response }) => {
              this.context.commit(
                Mutations.SET_ERROR,
                response?.data?.error ?? "GENERIC"
              );
              reject();
            });
          //credentials["id"] = code.data.id;
          //credentials["code"] = code.data.code;
          this.context.commit(Mutations.SET_AUTH, { id, code, email, token });
          if (code == null) {
            resolve("sign-up");
            return;
          }
          resolve("dashboard");
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_ERROR,
            response?.data?.error ?? "GENERIC"
          );
          reject();
        });
    });
  }

  @Action({ rawError: true })
  [Actions.LOGIN](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post("websps/auth", urlEncode(credentials))
        .then(async () => {
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_ERROR,
            response?.data?.error ?? "GENERIC"
          );
          reject();
        });
    });
  }

  @Action({ rawError: true })
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
    this.context.commit(Mutations.CLEAR_PACKETS);
  }

  @Action({ rawError: true })
  [Actions.ACCEPT]({ packetId }) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post("notify/manufacture_v2", {
        //eslint-disable-next-line
        manufacturer_number: packetId,
      })
        .then(() => {
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_ERROR,
            response?.data?.error ?? "GENERIC"
          );
          reject();
        });
    });
  }

  @Action({ rawError: true })
  [Actions.FINISH]({ packetId }) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post("websps/finish", {
        packetId: packetId,
      })
        .then(() => {
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_ERROR,
            response?.data?.error ?? "GENERIC"
          );
          reject();
        });
    });
  }

  @Action({ rawError: true })
  [Actions.PICKUP]({ packetId }) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post("websps/pickup", {
        packetId: packetId,
      })
        .then(() => {
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_ERROR,
            response?.data?.error ?? "GENERIC"
          );
          reject();
        });
    });
  }

  @Action({ rawError: true })
  [Actions.DOWNLOAD](data) {
    return new Promise<object>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get("websps/download", data, {
        responseType: "blob",
        //} as paths["/invoices/getInvoice"]["get"]["parameters"]["query"])
      })
        .then((response) => {
          resolve(response);
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_ERROR,
            response?.data?.error ?? "GENERIC"
          );
          reject();
        });
    });
  }

  @Action({ rawError: true })
  [Actions.MANUFACTURE](data) {
    return new Promise<object>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get("notify/manufacture_v2", data, {
        responseType: "blob",
      })
        .then((response: object) => {
          resolve(response);
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_ERROR,
            response?.data?.error ?? "GENERIC"
          );
          reject();
        });
    });
  }

  @Action({ rawError: true })
  [Actions.SHIPPING]({ packetId }) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post("websps/gls", {
        packetId: packetId,
      })
        .then(() => {
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_ERROR,
            response?.data?.error ?? "GENERIC"
          );
          reject();
        });
    });
  }

  @Action({ rawError: true })
  [Actions.LABELS]({ packetIds }) {
    return new Promise<object>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get(
        "websps/gls",
        {
          ids: JSON.stringify(packetIds),
        },
        {
          responseType: "blob",
        }
      )
        .then((response: object) => {
          resolve(response);
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_ERROR,
            response?.data?.error ?? "GENERIC"
          );
          reject();
        });
    });
  }

  @Action({ rawError: true })
  [Actions.QRCODE]() {
    return new Promise<object>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get("websps/qrcode", "", {
        responseType: "blob",
      })
        .then((response: object) => {
          resolve(response);
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_ERROR,
            response?.data?.error ?? "GENERIC"
          );
          reject();
        });
    });
  }

  @Action({ rawError: true })
  [Actions.AGENDA]() {
    return new Promise<object>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get("websps/agenda", "")
        .then((response: object) => {
          resolve(response);
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_ERROR,
            response?.data?.error ?? "GENERIC"
          );
          reject();
        });
    });
  }

  @Action({ rawError: true })
  [Actions.FORMATS]() {
    return new Promise<object>((resolve, reject) => {
      ApiService.get("websps/formats", "")
        .then((response: object) => {
          resolve(response);
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_ERROR,
            response?.data?.error ?? "GENERIC"
          );
          reject();
        });
    });
  }

  @Action({ rawError: true })
  [Actions.REGISTER](formData) {
    return new Promise<void>((resolve, reject) => {
      const token = JSON.parse(this.token ?? "");
      formData.append("email", token.email);
      ApiService.setHeader();
      ApiService.post("websps", formData)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_ERROR,
            response?.data?.error ?? "GENERIC"
          );
          reject();
        });
    });
  }

  @Action({ rawError: true })
  [Actions.INVOICE]({ id }) {
    return new Promise<object>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get("invoice/show", id)
        .then((data) => {
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_ERROR,
            response?.data?.error ?? "GENERIC"
          );
          reject();
        });
    });
  }

  @Action({ rawError: true })
  [Actions.INVOICES]() {
    return new Promise<object>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get("invoice")
        .then((data) => {
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_ERROR,
            response?.data?.error ?? "GENERIC"
          );
          reject();
        });
    });
  }

  @Action({ rawError: true })
  [Actions.NEW_INVOICE]() {
    return new Promise<object>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post("invoice/new", {})
        .then((data) => {
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_ERROR,
            response?.data?.error ?? "GENERIC"
          );
          reject();
        });
    });
  }

  @Action({ rawError: true })
  [Actions.RUNNING_TOTAL]() {
    return new Promise<object>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post("invoice/running_total", {})
        .then((data) => {
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_ERROR,
            response?.data?.error ?? "GENERIC"
          );
          reject();
        });
    });
  }

  @Action({ rawError: true })
  [Actions.INVOICE_DOWNLOAD]({ locale, invoiceNum }) {
    return new Promise<object>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get(`invoice/download/${locale}`, invoiceNum, {
        responseType: "blob",
      })
        .then((data) => {
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_ERROR,
            response?.data?.error ?? "GENERIC"
          );
          reject();
        });
    });
  }

  /*
  @Action({ rawError: true })
  [Actions.FORGOT_PASSWORD](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("forgot_password", payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
          resolve();
        })
        .catch(({ response }) => {
          console.log(response.data.errors);
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }*/

  @Action({ rawError: true })
  [Actions.VERIFY_AUTH]() {
    return !!getToken(ID_TOKEN_KEY);
  }

  /*@Action({ rawError: true })
  [Actions.UPDATE_USER](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("update_user", payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_USER, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }*/
}
