
import BrevoChat from "./components/BrevoChat.vue";
import { defineComponent, onMounted } from "vue";
import { useStore } from "vuex";
import { Actions, Mutations } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "app",
  components: {
    BrevoChat,
  },
  setup() {
    const store = useStore();
    store.dispatch(Actions.FETCH_CURRENCIES);

    onMounted(() => {
      /**
       * this is to override the layout config using saved data from localStorage
       * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
       */
      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);
    });
  },
});
