import { PriceWithCurrency, pR } from "@/common/dynamic_prices";

/**
 * @description converts string input to boolean
 * @param input: string
 * @returns {boolean | undefined}
 */
export const convertToBoolean = (input: string): boolean | undefined => {
  try {
    return JSON.parse(input.toLowerCase());
  } catch (e) {
    return undefined;
  }
};

/**
 * @description converts object to URLencoded object
 * @param input: object
 * @returns {void}
 */
export const urlEncode = (input: object): object => {
  const encodedObj = {};
  Object.keys(input).forEach((el) => {
    encodedObj[el] = encodeURIComponent(input[el]);
  });
  return encodedObj;
};

export const dateFromTimestamp = (
  data: object,
  index: number,
  keywords: string[]
): void => {
  for (const item of keywords) {
    const dt = new Date(data[index][item]);
    const formattedDate =
      (dt.getDate() > 9 ? dt.getDate() : "0" + dt.getDate()) +
      "." +
      (dt.getMonth() + 1 > 9 ? dt.getMonth() + 1 : "0" + (dt.getMonth() + 1)) +
      "." +
      dt.getFullYear();

    const formattedTime =
      (dt.getHours() > 9 ? dt.getHours() : "0" + dt.getHours()) +
      ":" +
      (dt.getMinutes() > 9 ? dt.getMinutes() : "0" + dt.getMinutes()) +
      ":" +
      (dt.getSeconds() > 9 ? dt.getSeconds() : "0" + dt.getSeconds());
    const targetArray = data[index].datetime;

    for (const obj of targetArray) {
      if (obj.id === item) {
        obj.value = { date: formattedDate, time: formattedTime };
        break;
      }
    }
  }
};

export const timestampConverter = (
  timestamp: number
): { date: string; time: string; days: number } => {
  const now = new Date();
  const dt = new Date(timestamp);
  const date =
    (dt.getDate() > 9 ? dt.getDate() : "0" + dt.getDate()) +
    "." +
    (dt.getMonth() + 1 > 9 ? dt.getMonth() + 1 : "0" + (dt.getMonth() + 1)) +
    "." +
    dt.getFullYear();
  const time =
    (dt.getHours() > 9 ? dt.getHours() : "0" + dt.getHours()) +
    ":" +
    (dt.getMinutes() > 9 ? dt.getMinutes() : "0" + dt.getMinutes()) +
    ":" +
    (dt.getSeconds() > 9 ? dt.getSeconds() : "0" + dt.getSeconds());
  const days = Math.round(
    (now.getTime() - dt.getTime()) / (1000 * 60 * 60 * 24)
  );

  return { date, time, days };
};

export const processAmount = (
  key: string,
  data: any,
  currencies: any,
  currency: string,
  lang: string
): void => {
  if (data[`${key}Raw`] == null) {
    data[`${key}Raw`] = data[key];
    const withCurrency: PriceWithCurrency = {
      amount: data[key],
      currency: currency,
      // eslint-disable-next-line @typescript-eslint/camelcase
      fractional_digits: currencies[currency].fractional_digits,
      // eslint-disable-next-line @typescript-eslint/camelcase
      round_factor: currencies[currency].round_factor,
    };
    data[key] = pR(lang, withCurrency);
  } else {
    const withCurrency: PriceWithCurrency = {
      amount: data[`${key}Raw`],
      currency: currency,
      // eslint-disable-next-line @typescript-eslint/camelcase
      fractional_digits: currencies[currency].fractional_digits,
      // eslint-disable-next-line @typescript-eslint/camelcase
      round_factor: currencies[currency].round_factor,
    };
    data[key] = pR(lang, withCurrency);
  }
};

export default {
  convertToBoolean,
  urlEncode,
  dateFromTimestamp,
  timestampConverter,
  processAmount,
};
