import { App } from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import { getToken, ID_TOKEN_KEY } from "@/core/services/JwtService";
import { AxiosResponse, AxiosRequestConfig } from "axios";
import { environment } from "@/environments/environment";
import { urlEncode } from "@/core/helpers/utils";

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);
    ApiService.vueInstance.axios.defaults.baseURL = environment.backendUrl;
  }

  /**
   * @description set the default HTTP request headers
   */
  public static setHeader(): void {
    const token = getToken(ID_TOKEN_KEY);
    if (token == null || typeof token != "string") {
      return;
    }
    const obj = urlEncode(JSON.parse(token));
    if (obj == null) {
      return;
    }
    /*
    ApiService.vueInstance.axios.defaults.headers.common["user_id"] =
      obj.id;
    ApiService.vueInstance.axios.defaults.headers.common["token"] =
      obj.password;
    */
    ApiService.vueInstance.axios.defaults.headers.common["partner-api-id"] =
      obj["email"];
    ApiService.vueInstance.axios.defaults.headers.common["partner-api-key"] =
      obj["token"];
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static query(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(resource, params).catch((error) => {
      throw new Error(`[KT] ApiService ${error}`);
    });
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static get(
    resource: string,
    slug: string | object = "",
    header: object | undefined = undefined
  ): Promise<AxiosResponse> {
    if (typeof slug == "string") {
      return ApiService.vueInstance.axios
        .get(`${resource}/${slug}`, header)
        .catch((error) => {
          throw new Error(`[KT] ApiService ${error}`);
        });
    } else {
      let requestStr = resource;
      for (const key in slug) {
        requestStr += `/${slug[key]}`;
      }
      return ApiService.vueInstance.axios
        .get(requestStr, header)
        .catch((error) => {
          throw new Error(`[KT] ApiService ${error}`);
        });
    }
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static post(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.post(`${resource}`, params);
  }

  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static update(
    resource: string,
    slug: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params);
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static put(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}`, params);
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static delete(resource: string): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.delete(resource).catch((error) => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  }
}

export default ApiService;
