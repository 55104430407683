import { createGettext } from "vue3-gettext";
import translations from "./language/translations.json";

export default createGettext({
  availableLanguages: {
    en: "English",
    sl: "Slovenščina",
  },
  defaultLanguage: "en",
  translations: translations,
});
