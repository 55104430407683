import {
  getToken,
  saveToken,
  ID_SHOW_FINISHED,
} from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { environment } from "@/environments/environment";
import gettext from "../../gettext";
import { CurrencyMap } from "@/store/modules/CurrencyModule";
import { convertToBoolean, urlEncode } from "@/core/helpers/utils";
import { PriceWithCurrency, pR } from "@/common/dynamic_prices";

const { $gettext, $ngettext } = gettext;

const photos = [
  "001-boy.svg",
  "009-boy-4.svg",
  "017-girl-8.svg",
  "025-girl-14.svg",
  "033-girl-18.svg",
  "041-girl-22.svg",
  "049-boy-22.svg",
  "002-girl.svg",
  "010-girl-4.svg",
  "018-girl-9.svg",
  "026-boy-10.svg",
  "034-boy-14.svg",
  "042-girl-23.svg",
  "050-girl-26.svg",
  "003-girl-1.svg",
  "011-boy-5.svg",
  "019-girl-10.svg",
  "027-girl-15.svg",
  "035-boy-15.svg",
  "043-boy-18.svg",
  "004-boy-1.svg",
  "012-girl-5.svg",
  "020-girl-11.svg",
  "028-girl-16.svg",
  "036-girl-19.svg",
  "044-boy-19.svg",
  "005-girl-2.svg",
  "013-girl-6.svg",
  "021-boy-8.svg",
  "029-boy-11.svg",
  "037-girl-20.svg",
  "045-boy-20.svg",
  "006-girl-3.svg",
  "014-girl-7.svg",
  "022-girl-12.svg",
  "030-girl-17.svg",
  "038-boy-16.svg",
  "046-girl-24.svg",
  "007-boy-2.svg",
  "015-boy-6.svg",
  "023-girl-13.svg",
  "031-boy-12.svg",
  "039-girl-21.svg",
  "047-girl-25.svg",
  "008-boy-3.svg",
  "016-boy-7.svg",
  "024-boy-9.svg",
  "032-boy-13.svg",
  "040-boy-17.svg",
  "048-boy-21.svg",
];

let existingWebsocket: WebSocket | null = null;

interface Wrap {
  color: string;
}
interface ImageListItemTrans {
  title: string | undefined;
  languages_code: string | undefined;
}
interface Datetime {
  date: string;
  time: string;
  timeRemaining: string;
  color: string;
}
interface Packet {
  packetId: number;
  s: number;
  date: number;
  products: Array<{
    product: ImageListItemTrans[];
    format: ImageListItemTrans[];
    count: string;
  }>;
  customer: {
    name: string;
    lastname: string;
    tracking: string;
    image: string;
  };
  payments: Array<{
    type: string;
    amount: number;
    amountCurrency: string;
    displayAmount: string;
  }>;
  shippingMethod: string;
  status: Wrap;
  action: Wrap;
  datetime: Datetime;
}

export interface Output {
  proPackets: Array<Packet>;
  printeePackets: Array<Packet>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function websocketSetup(
  context: any,
  socket: WebSocket,
  currencies: CurrencyMap
): void {
  //console.log(context);
  //console.log(typeof context);
  socket.addEventListener("message", (event: MessageEvent) => {
    const jsonData: Output = JSON.parse(event.data);
    const payload = { json: jsonData, currencies };
    //json.packets.sort((a, b) => b.date - a.date);
    context.commit(Mutations.PACKETS_UPDATE, payload);
  });
}

function initWebsocket(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  context: any,
  url: string,
  timeoutMs: number,
  numberOfRetries: number,
  currencies: CurrencyMap
) {
  let hasReturned = false;
  const rejectInternal = () => {
    setTimeout(function () {
      console.log(url);
      console.info("opening websocket timed out: " + url.split("&")[0]);
      /*console.log(timeoutMs);
      console.log(numberOfRetries);
      console.log(hasReturned);*/
      if (numberOfRetries <= 0) {
        return;
      } else if (!hasReturned) {
        hasReturned = true;
        console.info(
          "retrying connection to websocket! url: " +
            url.split("&")[0] +
            ", remaining retries: " +
            (numberOfRetries - 1)
        );
        initWebsocket(context, url, timeoutMs, numberOfRetries - 1, currencies);
      }
    }, timeoutMs);
  };
  if (
    !existingWebsocket ||
    existingWebsocket.readyState != existingWebsocket.OPEN
  ) {
    if (existingWebsocket) {
      existingWebsocket.close();
    }
    const websocket = new WebSocket(url);
    websocket.onopen = (event) => {
      console.log(event);
      if (hasReturned) {
        websocket.close();
      } else {
        //console.info("websocket to open! url: " + url.split("&")[0]);
        existingWebsocket = websocket;
        websocketSetup(context, websocket, currencies);
      }
    };
    websocket.onclose = (event) => {
      console.log(event);
      console.log("onclose");
      //console.info("websocket closed! url: " + url.split("&")[0]);
      if (event.code !== 3000) {
        rejectInternal();
      }
    };
    websocket.onerror = (event) => {
      console.log("onerror");
      console.log(event);
      //console.info("websocket error! url: " + url.split("&")[0]);
      rejectInternal();
    };
  } else {
    websocketSetup(context, existingWebsocket, currencies);
  }
}

function widget13(packet: Packet) {
  if (packet.customer.image == null) {
    packet.customer.image = `media/svg/avatars/${
      photos[Math.floor(packet.date % photos.length)]
    }`;
  }
  const tstamp = packet.date;
  const now = Date.now();
  const dt = new Date(tstamp);
  const diff1 = (now - dt.getTime()) / 1000 / 60 / 60;
  const diff2 = (diff1 - Math.floor(diff1)) * 60;
  const diff3 = (diff2 - Math.floor(diff2)) * 60;
  packet.datetime = {
    date:
      (dt.getDate() > 9 ? dt.getDate() : "0" + dt.getDate()) +
      "/" +
      (dt.getMonth() + 1 > 9 ? dt.getMonth() + 1 : "0" + (dt.getMonth() + 1)) +
      "/" +
      dt.getFullYear(),
    time:
      (dt.getHours() > 9 ? dt.getHours() : "0" + dt.getHours()) +
      ":" +
      (dt.getMinutes() > 9 ? dt.getMinutes() : "0" + dt.getMinutes()) +
      ":" +
      (dt.getSeconds() > 9 ? dt.getSeconds() : "0" + dt.getSeconds()) +
      " " +
      (dt.getDate() > 9 ? dt.getDate() : "0" + dt.getDate()) +
      "/" +
      (dt.getMonth() + 1 > 9 ? dt.getMonth() + 1 : "0" + (dt.getMonth() + 1)) +
      "/" +
      dt.getFullYear(),
    timeRemaining:
      (Math.floor(diff1) > 9 ? Math.floor(diff1) : "0" + Math.floor(diff1)) +
      ":" +
      (Math.floor(diff2) > 9 ? Math.floor(diff2) : "0" + Math.floor(diff2)) +
      ":" +
      (Math.floor(diff3) > 9 ? Math.floor(diff3) : "0" + Math.floor(diff3)),
    color:
      Math.floor(diff1) > 12
        ? Math.floor(diff1) > 24
          ? "danger"
          : "warning"
        : "success",
  };
  switch (packet.s) {
    case 5:
      if (packet.shippingMethod === "local_pickup") {
        packet.status = { color: "danger" };
      } else {
        packet.status = { color: "primary" };
      }
      break;
    case 4:
      packet.status = { color: "success" };
      break;
    case 3:
      packet.status = { color: "warning" };
      break;
    default:
      packet.status = { color: "info" };
      break;
  }
  switch (packet.s) {
    case 5:
      packet.action = { color: "secondary" };
      break;
    case 4:
      if (packet.shippingMethod === "local_pickup") {
        packet.action = { color: "danger" };
      } else {
        packet.action = { color: "primary" };
      }
      break;
    case 3:
      packet.action = { color: "success" };
      break;
    default:
      packet.action = { color: "warning" };
      break;
  }
}

function getWeekNum(d: Date): number {
  const firstDay = new Date(d.getFullYear(), 0, 2);
  const numOfDays = Math.floor(
    (d.getTime() - firstDay.getTime()) / (24 * 60 * 60 * 1000)
  );
  return Math.ceil(numOfDays / 7);
}
function dynamicGroupData(index: number, d: Date): string {
  switch (index) {
    case 1:
      return `${getWeekNum(d)}. week`;
    case 2:
      return d.toLocaleString("default", {
        day: "numeric",
        month: "numeric",
      });
    default:
      return d.toLocaleString("default", { month: "short" });
  }
}
function min2ElementsInSeries(index: number, item: string): string {
  switch (index) {
    case 1: {
      return `${Number(item.split(".")[0]) - 1}. week`;
    }
    case 2: {
      const split = item.split(/\/|\./);
      const dt = new Date(`${split[1] ?? 1} ${split[0] ?? 1} 2000`);
      dt.setDate(dt.getDate() - 1);
      return dt.toLocaleString("default", {
        day: "numeric",
        month: "numeric",
      });
    }
    default: {
      const dt = new Date(`1 ${item} 2000`);
      const d = new Date();
      d.setMonth((dt.getMonth() - 1) % 12);
      return d.toLocaleString("default", { month: "short" });
    }
  }
}

function widget4(packets: Array<Packet>, currencies: CurrencyMap) {
  //TODO impement chartOptions for both widgets. Bellow const variables should be in class so they can be accessed in widget4
  let obj;
  const data: Array<{ keys: Array<string>; values: Array<number> }> = [];
  const chartData: Array<{
    chartOptions: object;
    series: object[];
    title: string;
    description: string;
  }> = [];
  const height = 164;
  const labelColor = "#3f4254";
  const baseColor = ["#50cd89", "#009ef7"];
  const lightColor = ["#e8fff3", "#ecf8ff"];
  for (let i = 0; i < 6; i++) {
    obj = [{}, {}];
    //console.log("FOR");
    for (const packet of packets) {
      if (packet.s != 5) continue;
      //console.log(packet);
      const dt: string = dynamicGroupData(i % 3, new Date(packet.date));
      const sum = [0, 0];
      if (i < 3) {
        for (const payment of packet.payments) {
          sum[0] += Number(payment.amount);
        }
        sum[0] -= 2 * packet.products.length;
        if (obj[0][dt] !== undefined) {
          obj[0][dt] += sum[0];
        } else {
          obj[0][dt] = sum[0];
        }
      } else {
        for (const product of packet.products) {
          sum[1] += Number(product.count);
        }
        if (obj[1][dt] !== undefined) {
          obj[1][dt] += sum[1];
        } else {
          obj[1][dt] = sum[1];
        }
      }
    }
    //console.log(obj);
    if (i < 3) {
      let keys = Object.keys(obj[0]);
      if (keys.length < 2) {
        if (keys.length === 0) {
          const dt: string = dynamicGroupData(i % 3, new Date());
          obj[0][dt] = 0;
          keys = Object.keys(obj[0]);
        }
        keys.unshift(min2ElementsInSeries(i % 3, keys[0]));
      }
      data.push({
        keys: keys,
        values: keys.map(function (j) {
          return obj[0][j] ?? 0;
        }),
      });
    } else {
      let keys = Object.keys(obj[1]);
      if (keys.length < 2) {
        if (keys.length === 0) {
          const dt: string = dynamicGroupData(i % 3, new Date());
          obj[1][dt] = 0;
          keys = Object.keys(obj[1]);
        }
        keys.unshift(min2ElementsInSeries(i % 3, keys[0]));
      }
      data.push({
        keys: keys,
        values: keys.map(function (j) {
          return obj[1][j] ?? 0;
        }),
      });
    }
    //console.log(data);
    if (i < 3) {
      //TODO automatic convertsion or currency picker
      const currency = "EUR";
      const titleWithCurrency: PriceWithCurrency = {
        amount: data[i].values.reduce((e1, e2) => e1 + e2),
        currency: currency,
        // eslint-disable-next-line @typescript-eslint/camelcase
        fractional_digits: currencies[currency].fractional_digits,
        // eslint-disable-next-line @typescript-eslint/camelcase
        round_factor: currencies[currency].round_factor,
      };

      chartData.push({
        chartOptions: {
          chart: {
            fontFamily: "inherit",
            type: "area",
            height: height,
            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            },
            sparkline: {
              enabled: true,
            },
          },
          plotOptions: {},
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "smooth",
            show: true,
            width: 2,
            colors: [baseColor[0]],
          },
          xaxis: {
            categories: data[i].keys,
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            labels: {
              show: false,
              style: {
                colors: labelColor,
                fontSize: "11px",
              },
            },
            crosshairs: {
              show: false,
              position: "front",
              stroke: {
                color: "#E3E6EF",
                width: 0,
                dashArray: 2,
              },
            },
            tooltip: {
              enabled: false,
            },
          },
          yaxis: {
            min: -3,
            max: Math.round(data[i].values.reduce((e1, e2) => e1 + e2)) + 2,
            labels: {
              show: false,
              style: {
                colors: labelColor,
                fontSize: "11px",
              },
            },
          },
          states: {
            normal: {
              filter: {
                type: "none",
                value: -1,
              },
            },
            hover: {
              filter: {
                type: "none",
                value: -1,
              },
            },
            active: {
              allowMultipleDataPointsSelection: false,
              filter: {
                type: "none",
                value: -1,
              },
            },
          },
          tooltip: {
            style: {
              fontSize: "11px",
            },
            y: {
              formatter: function (val) {
                //TODO automatic convertsion or currency picker
                const currency = "EUR";
                const valWithCurrency: PriceWithCurrency = {
                  amount: val,
                  currency: currency,
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  fractional_digits: currencies[currency].fractional_digits,
                  // eslint-disable-next-line @typescript-eslint/camelcase
                  round_factor: currencies[currency].round_factor,
                };
                return pR(gettext.current, valWithCurrency);
              },
            },
          },
          fill: {
            type: "gradient",
            gradient: {
              stops: [-1, 100],
            },
          },
          colors: [baseColor[0]],
          markers: {
            colors: [baseColor[0]],
            strokeColor: [lightColor[0]],
            strokeWidth: 2,
          },
        },
        series: [
          {
            name: $gettext("Net Profit"),
            data: data[i].values,
          },
        ],
        title: pR(gettext.current, titleWithCurrency),
        description:
          i % 3 == 0
            ? $gettext("Monthly income")
            : i % 3 == 1
            ? $gettext("Weekly income")
            : $gettext("Daily income"),
      });
    } else {
      chartData.push({
        chartOptions: {
          chart: {
            fontFamily: "inherit",
            type: "area",
            height: height,
            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            },
            sparkline: {
              enabled: true,
            },
          },
          plotOptions: {},
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "smooth",
            show: true,
            width: 2,
            colors: [baseColor[1]],
          },
          xaxis: {
            categories: data[i].keys,
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            labels: {
              show: false,
              style: {
                colors: labelColor,
                fontSize: "11px",
              },
            },
            crosshairs: {
              show: false,
              position: "front",
              stroke: {
                color: "#E3E6EF",
                width: 0,
                dashArray: 2,
              },
            },
            tooltip: {
              enabled: false,
            },
          },
          yaxis: {
            min: -3,
            max: Math.round(data[i].values.reduce((e1, e2) => e1 + e2)) + 2,
            labels: {
              show: false,
              style: {
                colors: labelColor,
                fontSize: "11px",
              },
            },
          },
          states: {
            normal: {
              filter: {
                type: "none",
                value: -1,
              },
            },
            hover: {
              filter: {
                type: "none",
                value: -1,
              },
            },
            active: {
              allowMultipleDataPointsSelection: false,
              filter: {
                type: "none",
                value: -1,
              },
            },
          },
          tooltip: {
            style: {
              fontSize: "11px",
            },
            y: {
              formatter: function (val) {
                const count = Math.round(val);
                return $ngettext(
                  "%{ count } photo",
                  "%{ count } photos",
                  count,
                  { count: count.toString() }
                );
              },
            },
          },
          fill: {
            type: "gradient",
            gradient: {
              stops: [-1, 100],
            },
          },
          colors: [baseColor[1]],
          markers: {
            colors: [baseColor[1]],
            strokeColor: [lightColor[1]],
            strokeWidth: 2,
          },
        },
        series: [
          {
            name: $gettext("Net Profit"),
            data: data[i].values,
          },
        ],
        title: (function () {
          const count = Math.round(data[i].values.reduce((e1, e2) => e1 + e2));
          return $ngettext("%{ count } photo", "%{ count } photos", count, {
            count: count.toString(),
          });
        })(),
        description:
          i % 3 == 0
            ? $gettext("Monthly photos printed")
            : i % 3 == 1
            ? $gettext("Weekly photos printed")
            : $gettext("Daily photos printed"),
      });
    }
    /*console.log({
      chart: chartData[i].chartOptions,
      series: chartData[i].series,
      title: chartData[i].title,
      desc: chartData[i].description,
      keys: data[i].keys,
    });*/
  }
  return chartData.length == 6 ? chartData : [{ title: "0 €" }];
}

@Module
export default class PacketsModule extends VuexModule {
  orderList: Output = { proPackets: [], printeePackets: [] };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  chartData: any = null;
  showFinished =
    convertToBoolean(getToken(ID_SHOW_FINISHED) ?? "false") ?? false;

  @Action({ rawError: true })
  async [Actions.LOGIN_TOKEN_CHANGED](token: string) {
    let currencies = (this.context.rootState as any).CurrencyModule.currencies;
    const objInternal = urlEncode(JSON.parse(token));
    // console.log(currencies);
    if (currencies == null) {
      await this.context.dispatch(Actions.FETCH_CURRENCIES);
      currencies = (this.context.rootState as any).CurrencyModule.currencies;
    }
    // console.log(currencies);
    if (objInternal != null && currencies != null) {
      //console.info("INIT");
      initWebsocket(
        this.context,
        `${environment.backendWebsocket}?query=packets&user_id=${objInternal["id"]}&email=${objInternal["email"]}&token=${objInternal["token"]}`,
        5000,
        10,
        currencies
      );
    }
  }

  @Action({ rawError: true })
  [Actions.LANGUAGE_CHANGED]() {
    this.context.commit(Mutations.TRANSLATE_GRAPHS);
  }

  @Action({ rawError: true })
  [Actions.SHOW_FINISHED_CHANGED]() {
    this.context.commit(Mutations.SAVE_SHOW_FINISHED);
  }

  @Mutation
  [Mutations.TRANSLATE_GRAPHS]() {
    const packets = this.orderList;
    if (packets !== null && packets.proPackets !== null) {
      this.chartData = widget4(
        packets.proPackets,
        (this.context.rootState as any).CurrencyModule.currencies
      );
    }
  }

  @Mutation
  [Mutations.PACKETS_UPDATE]({
    json,
    currencies,
  }: {
    json: Output;
    currencies: CurrencyMap;
  }) {
    //TODO remove double packets loops if possible

    for (const packet of json.proPackets) {
      if (packet == undefined) continue;
      for (const payment of packet.payments) {
        const currency = payment.amountCurrency;
        const priceWithCurrency: PriceWithCurrency = {
          amount: payment.amount,
          currency: currency,
          // eslint-disable-next-line @typescript-eslint/camelcase
          fractional_digits: currencies[currency].fractional_digits,
          // eslint-disable-next-line @typescript-eslint/camelcase
          round_factor: currencies[currency].round_factor,
        };
        payment.displayAmount = pR(gettext.current, priceWithCurrency);
      }
      widget13(packet);
    }

    for (const packet of json.printeePackets) {
      if (packet == undefined) continue;
      for (const payment of packet.payments) {
        const currency = payment.amountCurrency;
        const priceWithCurrency: PriceWithCurrency = {
          amount: payment.amount,
          currency: currency,
          // eslint-disable-next-line @typescript-eslint/camelcase
          fractional_digits: currencies[currency].fractional_digits,
          // eslint-disable-next-line @typescript-eslint/camelcase
          round_factor: currencies[currency].round_factor,
        };
        payment.displayAmount = pR(gettext.current, priceWithCurrency);
      }
      widget13(packet);
    }

    this.orderList = json;
    this.chartData = widget4(json.proPackets, currencies);
    //console.log(this.chartData);
  }

  @Mutation
  [Mutations.CLEAR_PACKETS]() {
    //console.log("CLEAR PACKETS")
    this.orderList = { proPackets: [], printeePackets: [] };
    this.chartData = null;
    existingWebsocket?.close(3000, "user logged out");
    existingWebsocket = null;
  }

  @Mutation
  [Mutations.SAVE_SHOW_FINISHED]() {
    saveToken(ID_SHOW_FINISHED, JSON.stringify(this.showFinished));
  }
}
