export interface CurrencyAware {
  currency: string;
  fractional_digits: number;
  round_factor: number;
}

export interface PriceWithCurrency extends CurrencyAware {
  amount: number;
}

export function dynPriceRound(price: number, roundFactor: number): number {
  return Math.round(price * roundFactor) / roundFactor;
}

export function pR(locale: string, price: PriceWithCurrency): string {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: price.currency,
    maximumFractionDigits: price.fractional_digits,
  }).format(dynPriceRound(price.amount, price.round_factor));
}
