import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";

interface Currency {
  code: string;
  fractional_digits: number;
  round_factor: number;
}
type CurrencyMap = {
  [key: string]: Currency;
};

@Module
export default class CurrencyModule extends VuexModule {
  public currencies: CurrencyMap | null = null;
  @Mutation
  public setCurrencies(data: CurrencyMap) {
    this.currencies = data;
  }

  @Action({ rawError: true })
  [Actions.FETCH_CURRENCIES]() {
    return new Promise<object>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get("currency/list", "")
        .then(({ data }) => {
          this.context.commit("setCurrencies", data);
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_ERROR,
            response?.data?.error ?? "GENERIC"
          );
          reject();
        });
    });
  }
}

export { CurrencyMap };
