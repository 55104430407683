import { createApp } from "vue";
import gettext from "./gettext";
import App from "./App.vue";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
//import i18n from "@/core/plugins/i18n";
//import VueFBCustomerChat from "vue-fb-customer-chat";

//imports for app initialization
//import MockAdapter from "@/core/mock/MockService";
import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";

import "@/core/plugins/keenthemes";
import "@/core/plugins/prismjs";
import "bootstrap";

const app = createApp(App);

/*app.use(VueFBCustomerChat, {
  //eslint-disable-next-line
  page_id: 1770843489827919,
  //eslint-disable-next-line
  theme_color: "#333333",
  locale: "en_US"
});*/
app.use(gettext);
//app.use(i18n);
app.use(store);
app.use(router);
app.use(ElementPlus);

ApiService.init(app);
//MockAdapter.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.mount("#app");
