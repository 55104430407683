import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
      {
        path: "/my-orders",
        name: "my-orders",
        component: () => import("@/views/MyOrders.vue"),
      },
      {
        path: "/printee-orders",
        name: "printee-orders",
        component: () => import("@/views/PrinteeOrders.vue"),
      },
      {
        path: "/invoices",
        name: "invoices",
        component: () => import("@/views/Invoices.vue"),
      },
      {
        path: "/invoice/:id",
        name: "invoice",
        component: () => import("@/views/Invoice.vue"),
      },
    ],
  },
  {
    path: "/sign-in",
    name: "sign-in",
    component: () => import("@/views/auth/SignIn.vue"),
  },
  {
    path: "/check",
    name: "check",
    component: () => import("@/views/auth/Check.vue"),
  },
  {
    path: "/callback",
    name: "callback",
    component: () => import("@/views/auth/Callback.vue"),
  },
  {
    path: "/sign-up",
    name: "sign-up",
    component: () => import("@/views/auth/SignUp.vue"),
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/error/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/error/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((data) => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  store.dispatch(Actions.VERIFY_AUTH).then((auth) => {
    if (auth && (data.name === "sign-in" || data.name === "check")) {
      router.push({ name: "dashboard" });
    } else if (
      !auth &&
      data.name !== "sign-in" &&
      data.name !== "check" &&
      data.name !== "callback"
    ) {
      router.push({ name: "sign-in" });
    }
  });
  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
