enum Actions {
  // action types
  IS_USER_AUTHENTICATED = "isUserAuthenticated",
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  CHECK = "check",
  LOGIN = "login",
  LOGOUT = "logout",
  ACCEPT = "accept",
  FINISH = "finish",
  PICKUP = "pickup",
  DOWNLOAD = "download",
  MANUFACTURE = "manufacture",
  SHIPPING = "shipping",
  LABELS = "labels",
  QRCODE = "qrcode",
  AGENDA = "agenda",
  FETCH_CURRENCIES = "fetch_currencies",
  FORMATS = "formats",
  REGISTER = "register",
  INVOICE = "invoice",
  INVOICES = "invoices",
  NEW_INVOICE = "newInvoice",
  RUNNING_TOTAL = "runningTotal",
  INVOICE_DOWNLOAD = "invoiceDownload",
  //UPDATE_USER = "updateUser",
  //FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  LOGIN_TOKEN_CHANGED = "loginTokenChanged",
  LANGUAGE_CHANGED = "languageChanged",
  SHOW_FINISHED_CHANGED = "showFinishedChanged",
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  //SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  PACKETS_UPDATE = "packetsUpdate",
  CLEAR_PACKETS = "clearPackets",
  INIT_WEBSOCKET = "initWebsocket",
  TRANSLATE_GRAPHS = "translateGraphs",
  SAVE_SHOW_FINISHED = "saveShowFinished",
}

export { Actions, Mutations };
