import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import PacketsModule from "@/store/modules/PacketsModule";
import CurrencyModule from "@/store/modules/CurrencyModule";

config.rawError = true;

export const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    PacketsModule,
    CurrencyModule,
  },
});

export default store;
