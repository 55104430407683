export const ID_TOKEN_KEY = "SPSdata";
export const ID_SHOW_FINISHED = "showFinished";

/**
 * @description get token form localStorage
 */
export const getToken = (tokenId: string): string | null => {
  return window.localStorage.getItem(tokenId);
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveToken = (tokenId: string, token: string): void => {
  window.localStorage.setItem(tokenId, token);
};

/**
 * @description remove token form localStorage
 */
export const destroyToken = (tokenId: string): void => {
  window.localStorage.removeItem(tokenId);
};

export default {
  ID_TOKEN_KEY,
  ID_SHOW_FINISHED,
  getToken,
  saveToken,
  destroyToken,
};
